import React, { ReactNode } from "react"
import clsx from "clsx"
import Lottie from "react-lottie"
import { createBreakpoint } from "react-use"
import S from "./auth-box.module.scss"

const useBreakpoint = createBreakpoint()

type Props = {
  title: string
  image?: string
  subtitle?: string
  animation?: {
    desktop?: any
    mobile?: any
  }
  children?: ReactNode
}

const AuthBox: React.FC<Props> = ({
  children = null,
  subtitle,
  image,
  title,
  animation,
}) => {
  const breakpoint = useBreakpoint()
  const imageType = animation ? "animation" : "image"

  return (
    <div className={S.box}>
      <div className="row no-gutters">
        <div className="col-md-5">
          <div
            className={clsx(
              S.imageWrapper,
              "d-flex h-100 align-items-center justify-content-center py-3 py-md-4 px-3",
            )}
          >
            {imageType === "animation" && animation && (
              <Lottie
                options={{
                  animationData:
                    breakpoint === "tablet" && animation.mobile
                      ? animation.mobile
                      : animation.desktop,
                }}
              />
            )}
            {imageType === "image" && (
              <img src={image} alt="" className="img-fluid" />
            )}
          </div>
        </div>
        <div className="col-md-7">
          <div className={S.contentWrapper}>
            <h1 className={S.title}>{title}</h1>

            {subtitle ? (
              <h2
                className={S.subtitle}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            ) : null}

            <div className="mt-4">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthBox
